import styled from "styled-components"

import { MBanner } from "src/ui/MBanner/MBanner"

export function MBannerDemo() {
  return (
    <Grid>
      <MBanner>This is a default banner</MBanner>

      <MBanner type="error">This is a 'critical' banner</MBanner>

      <MBanner type="error">This is an 'emergency' banner</MBanner>

      <MBanner type="info">This is an 'info' banner</MBanner>

      <MBanner type="warning">This is a 'warning' banner</MBanner>

      <MBanner type="good">This is an 'ok' banner</MBanner>

      <MBanner type="neutral">This is a 'neutral' banner</MBanner>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: 1rem;
`
