import styled from "styled-components"

import { Routes } from "src/router/routes"
import { colorScale } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MLinkStyle, MLinkStyleProps } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MLinkDemo() {
  return (
    <div>
      <Box>
        <div>
          <Title>External links</Title>

          <LinkBox>
            <ExternalLink href="https://www.minut.com">
              https://www.minut.com
            </ExternalLink>
            <ExternalLink href="https://www.minut.com" showExternalIcon={false}>
              No icon
            </ExternalLink>
            <ExternalLink href="https://www.minut.com" underline="never">
              Underline never
            </ExternalLink>
            <ExternalLink href="https://www.minut.com" underline="onHover">
              Underline onHover
            </ExternalLink>

            <ExternalLink href="https://www.minut.com" color="emergency">
              Supports MText colors
            </ExternalLink>

            <ContrastBox>
              <ExternalLink href="https://www.minut.com" color="contrast">
                Supports MText colors
              </ExternalLink>
            </ContrastBox>

            <div>
              In some cases link color should inherit the text color of the
              context.{" "}
              <ExternalLink href="https://www.minut.com" color="unset">
                Like this.
              </ExternalLink>
            </div>
          </LinkBox>
        </div>

        <StyledDivider />

        <div>
          <Title>Internal links</Title>

          <LinkBox>
            <InternalLink to={Routes.Dashboard.location()}>
              Go to dashboard
            </InternalLink>
            <InternalLink to={Routes.Dashboard.location()} underline="never">
              Go to dashboard
            </InternalLink>
            <InternalLink to={Routes.Dashboard.location()} underline="onHover">
              Go to dashboard
            </InternalLink>

            <InternalLink to={Routes.Dashboard.location()} color="emergency">
              Go to dashboard
            </InternalLink>

            <ContrastBox>
              <InternalLink to={Routes.Dashboard.location()} color="contrast">
                Go to dashboard
              </InternalLink>
            </ContrastBox>

            <div>
              In some cases link color should inherit the text color of the
              context.{" "}
              <InternalLink to={Routes.Dashboard.location()} color="unset">
                Like this.
              </InternalLink>
            </div>
          </LinkBox>
        </div>

        <StyledDivider />

        <div>
          <Title>Custom usage of link style</Title>

          <LinkBox>
            <StyledText>I'm using the link style</StyledText>

            <StyledText $underline="never">
              Supports link style props
            </StyledText>

            <StyledText $underline="onHover">Underline on hover</StyledText>

            <StyledText $color="emergency">Supports MText colors</StyledText>

            <ContrastBox>
              <StyledText $color="contrast">Supports MText colors</StyledText>
            </ContrastBox>
          </LinkBox>
        </div>
      </Box>
    </div>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <MText variant="subtitle" marginBottom={spacing.M}>
      {children}
    </MText>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const LinkBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: flex-center;
  flex-wrap: wrap;
`

const ContrastBox = styled(LinkBox)`
  background: ${colorScale.hejmo[700]};
  padding: ${spacing.L};
  border-radius: 6px;
`

const StyledDivider = styled(Divider)`
  margin-block: ${spacing.L};
`

const StyledText = styled.div<MLinkStyleProps>`
  ${MLinkStyle}
`
