import styled from "styled-components"

import { CssInJsContainerQuery } from "src/components/Sandbox/ContainerQueriesDemo/CssInJsContainerQuery"
import { LayoutShowCase } from "src/components/Sandbox/ContainerQueriesDemo/LayoutShowCase"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"

export function ContainerQueriesDemo() {
  return (
    <Box>
      <div>
        Example: Layout, padding & gap adaptions based on container width
      </div>

      <ExpandableSection
        title={<div>Layout</div>}
        variant="card"
        storeState
        initialOpen
        id="container-query-layout"
      >
        <LayoutShowCase />
      </ExpandableSection>

      <ExpandableSection
        title={<div>CSS-in-JS variant</div>}
        variant="card"
        storeState
        initialOpen
        id="css-in-js-container-query"
      >
        <CssInJsContainerQuery />
      </ExpandableSection>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: 1rem;
`
