import { useState } from "react"
import styled from "styled-components"

import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"

import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { colors, greyScale } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function UsersFilter({
  selectedValues,
  onChange,
}: {
  selectedValues: {
    value: string
    label: string
  }[]
  onChange: (
    selected: {
      value: string
      label: string
    }[]
  ) => void
}) {
  const [searchInput, setSearchInput] = useState("")

  const fetchUsers = useFetchParadiseUsers({
    filter: {
      email: searchInput,
    },
  })

  return (
    <div>
      <StyledSearch>
        <TextField
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {fetchUsers.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Search color="disabled" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </StyledSearch>

      {fetchUsers.data?.users.length === 0 && searchInput.length > 0 && (
        <EmptyBox>
          <MText variant="nano">Not found</MText>
        </EmptyBox>
      )}

      <ItemsList>
        {fetchUsers.data?.users.map((user) => (
          <Option
            key={user.user_id}
            onClick={() => {
              if (
                selectedValues.findIndex((v) => v.value === user.user_id) === -1
              ) {
                onChange([
                  ...selectedValues,
                  { label: user.email, value: user.user_id },
                ])
              } else {
                onChange(selectedValues.filter((s) => s.value !== user.user_id))
              }
            }}
            tabIndex={-1}
          >
            <Item>
              <StyledCheckbox
                checked={
                  selectedValues.findIndex((v) => v.value === user.user_id) > -1
                }
                name={user.user_id}
              />
              <StyledNanoText>{user.email}</StyledNanoText>
            </Item>
          </Option>
        ))}
      </ItemsList>
    </div>
  )
}

const StyledSelect = styled.div`
  padding: ${spacing.S} 0;
  width: 300px;
`

const Option = styled.div`
  padding: 4px ${spacing.S} 4px ${spacing.M};

  &:hover {
    background-color: ${colors.backgroundGrayV2};
  }
`

const StyledSearch = styled.div`
  padding: 0 ${spacing.S} ${spacing.S} ${spacing.S};
`

const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.XL};
  color: ${colors.minimumGray};
`

const ItemsList = styled.div`
  max-height: 300px;
  overflow: auto;
  border-top: 1px solid ${greyScale[50]};
  border-bottom: 1px solid ${greyScale[50]};
`

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
`

const StyledCheckbox = styled(Checkbox)`
  padding: ${spacing.XS} ${spacing.M} ${spacing.XS} ${spacing.XS};
`

const StyledNanoText = styled(MText)`
  color: ${colors.brandText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
