import { useState } from "react"
import { styled } from "styled-components"

import { colorsV2 } from "src/ui/colors"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TTextVariant = MTextProps["variant"]
type TTextAlign = MTextProps["textAlign"]
type TTextColor = MTextProps["color"]
type TMarginBottom = MTextProps["marginBottom"]

export function MTextDemo() {
  const [color, setColor] = useState<TTextColor>("primary")
  const [variant, setVariant] = useState<TTextVariant>("body")
  const [marginBottom, setMarginBottom] = useState<TMarginBottom>(0)
  const [align, setAlign] = useState<TTextAlign>("start")

  return (
    <Box>
      <Box1>
        <TestBox style={{ gridColumn: "1 / -1" }}>
          <div>Typographic scale</div>

          <MText variant="heading1">Heading 1</MText>
          <MText variant="heading2">Heading 2</MText>
          <MText variant="heading3">Heading 3</MText>
          <MText variant="subtitle">Subtitle</MText>
          <MText variant="subtitleS">Subtitle Small</MText>
          <MText variant="body">Body</MText>
          <MText variant="bodyS">Body Small</MText>
          <MText variant="nano">Nano</MText>
        </TestBox>
      </Box1>

      <MTextPlayground>
        <MText
          variant="bodyS"
          color="secondary"
          marginBottom={spacing.XS2}
          style={{ gridColumn: "1 / -1" }}
        >
          Playground
        </MText>

        <MTextPlaygroundOptions>
          <select
            onChange={(e) => setVariant(e.target.value as TTextVariant)}
            value={variant}
          >
            <Option value={"heading1"}>Heading 1</Option>
            <Option value={"heading2"}>Heading 2</Option>
            <Option value={"heading3"}>Heading 3</Option>
            <Option value={"subtitle"}>Subtitle</Option>
            <Option value={"subtitleS"}>SubtitleS</Option>
            <Option value={"body"}>Body</Option>
            <Option value={"bodyS"}>BodyS</Option>
            <Option value={"nano"}>Nano</Option>
          </select>

          <select
            onChange={(e) => setColor(e.target.value as TTextColor)}
            value={color}
          >
            <option value={"primary"}>Primary</option>
            <option value={"secondary"}>Secondary</option>
            <option value={"tertiary"}>Tertiary</option>
            <option value={"emergency"}>Emergency</option>
            <option value={"good"}>Good</option>
            <option value={"warning"}>Warning</option>
            <option value={"link"}>Link</option>
            <option value={"unset"}>Unset</option>
          </select>

          <select
            onChange={(e) => setMarginBottom(e.target.value as TMarginBottom)}
            value={marginBottom}
          >
            <option value={0}>Margin bottom</option>
            {Object.entries(spacing).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>

          <label>
            <MText variant="bodyS" color="secondary" as="span">
              Align end
            </MText>
            <input
              type="checkbox"
              checked={align === "end"}
              onChange={(e) => setAlign(e.target.checked ? "end" : "start")}
            ></input>
          </label>
        </MTextPlaygroundOptions>

        <MText variant="bodyS" color="secondary" marginBottom={spacing.XS2}>
          Example:
        </MText>
        <MText
          color={color || "primary"}
          variant={variant}
          textAlign={align}
          marginBottom={marginBottom}
          style={{
            gridColumn: "1 / -1",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </MText>
      </MTextPlayground>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: 1rem;
`

const MTextPlayground = styled.div`
  border: 1px solid ${colorsV2.divider};
  padding: ${spacing.S};
`

const MTextPlaygroundOptions = styled.div`
  display: flex;
  gap: ${spacing.S};
  margin-bottom: ${spacing.M};
`

const Box1 = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;

  display: grid;
  grid-template-columns: 1fr 2fr;
`

const TestBox = styled.div`
  display: grid;
  gap: ${spacing.M};
  border: 1px solid ${colorsV2.divider};
  padding: ${spacing.M};

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`

function Option({
  value,
  children,
  disabled,
}: {
  value: TTextVariant
  disabled?: boolean
  children: React.ReactNode
}) {
  return (
    <option disabled={disabled} value={value}>
      {children}
    </option>
  )
}
