import { useState } from "react"

import { HomesDropdown } from "src/components/Dropdown/HomesDropdown"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import { Routes } from "src/router/routes"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"

export function MSelectDemo() {
  const [selectedHome, setSelectedHome] = useState<TMaybeHome>(null)
  return (
    <article>
      <HomesDropdown value={selectedHome} onSelect={setSelectedHome} />

      <br></br>

      <MCardInteractive
        linkTo={Routes.Home.location(selectedHome?.home_id ?? "").pathname}
      >
        <div>Home name: {selectedHome?.name ?? "None"}</div>
        <div>Home id: {selectedHome?.home_id ?? "N/A"}</div>
      </MCardInteractive>
    </article>
  )
}
