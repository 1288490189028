import styled from "styled-components"

import { Divider } from "src/ui/Divider/Divider"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MBadgeDemo() {
  return (
    <div>
      <PillsContainer>
        <MBadge>Default appearance</MBadge>
      </PillsContainer>

      <StyledDivider />

      <MText variant="subtitle">Colors</MText>
      <PillsContainer>
        <MBadge color="info">Info</MBadge>
        <MBadge color="neutral">Neutral</MBadge>
        <MBadge color="good">Good</MBadge>
        <MBadge color="warning">Warning</MBadge>
        <MBadge color="error">Error</MBadge>
        <MBadge color="promotional">Promotional</MBadge>
      </PillsContainer>

      <StyledDivider />

      <MText variant="subtitle">With border</MText>
      <PillsContainer>
        <MBadge border>Info</MBadge>
        <MBadge border color="neutral">
          Neutral
        </MBadge>
        <MBadge border color="good">
          Good
        </MBadge>
        <MBadge border color="warning">
          Warning
        </MBadge>
        <MBadge border color="error">
          Error
        </MBadge>
        <MBadge border color="promotional">
          Promotional
        </MBadge>
      </PillsContainer>

      <StyledDivider />

      <MText variant="subtitle">Border radius</MText>
      <PillsContainer>
        <MBadge borderRadius="round">Round</MBadge>
        <MBadge borderRadius="square">Square</MBadge>
      </PillsContainer>
    </div>
  )
}

const PillsContainer = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  margin-top: ${spacing.XS};
`
const StyledDivider = styled(Divider)`
  margin-block: ${spacing.L};
`
